import { render, staticRenderFns } from "./IconLayout.vue?vue&type=template&id=87615140&scoped=true"
import script from "./IconLayout.vue?vue&type=script&lang=js"
export * from "./IconLayout.vue?vue&type=script&lang=js"
import style0 from "./IconLayout.vue?vue&type=style&index=0&id=87615140&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87615140",
  null
  
)

export default component.exports